jQuery(document).ready(function () {
    $(window).bind('mousewheel', function(event) {
        if (event.originalEvent.wheelDelta < 0) {
            $('.call-us-main').addClass('call-us-main-visible');
        }
    });

    $('body').bind('touchmove', function(event) {
        if (window.pageYOffset >= 325) {
            $('.call-us-main').addClass('call-us-main-visible');
        }
    });

    $('#contact-modal').on('show.bs.modal', function (event) {
        if (window.innerWidth < 600) {
            return event.preventDefault();
        }
    });

    if (
        window.innerWidth > 600 &&
        !document.cookie.split('; ').find(row => row.startsWith('contact-modal-on-visit'))
    ) {
        let date = new Date();

        date.setDate(date.getDate() + 1);

        let expires = date.toGMTString();

        document.cookie = 'contact-modal-on-visit=true; expires=' + expires + ';';

        $('#contact-modal').modal('show');
    }

    $(document).scroll(function () {
        let y = $(this).scrollTop();

        if (window.innerWidth > 600 && y > 50) {
            $('.free-time-message').addClass('d-block');
        }
    });

    $('.read-more-btn').click(function () {
        $(this).parent().find('.read-more').toggle();
        $(this).parent().find('.read-less').toggle();
        $(this).html('Czytaj ' + ($(this).html() === 'Czytaj więcej...' ? 'mniej...' : 'więcej...'));
    });
});
